import './index.scss';
// import '../../assets/scripts/clipboard.min';
// import '../../assets/scripts/layer/layer';
import Swiper from 'swiper/swiper-bundle.min.js'
// var swiper = new Swiper(".model3 .mySwiper", {
//     slidesPerView: 3,
//     grid: {
//         rows: 2,
//     },
//     navigation: {
//         nextEl: ".model3 .swiper-button-next",
//         prevEl: ".model3 .swiper-button-prev",
//     },
// });

// var oDiv = $('.dialog')
// $('.ewmShow').on('click', function (e) {
//     console.log(11);
//     if($(this).hasClass('active')){
//         $(this).removeClass('active');
//     }
//     else{
//         $(this).addClass('active').siblings().removeClass('active');
//         $('.dialog').hide().eq($('.show').index(this)).show();
//         e.stopPropagation();
//     }  
// })
// $(document).click(function(e){		
//     if(!oDiv.is(e.target)){
//         oDiv.hide()
//     }				
// })

// var clipboard = new ClipboardJS('.copy-btn')
// clipboard.on('success', function (e) {
//     layer.msg('复制成功')
// })
